// GORE FINE WIRE THEME
// EM/REM VALUES ASSUME 16PX BROWSER DEFAULT FONT SIZE

// CSS IMPORTS
import "../css/typography.css"

// THEME EXPORT
export default {
  breakpoints: ["40rem", "52rem", "60rem", "72rem"],
  sizes: {
    heroWidth: "72rem",
    containerWidth: "60rem", // 960px
    contentWidth: "56.25rem", // 900px (containerWidth - (defaultPadding * 2))
  },
  space: {
    defaultPadding: "1.875rem", // Default site-wide padding of 30px, ((containerWidth - contentWidth) / 2)
    columnSpacing: "1.625rem", // 26px of space before or after a column in a 2-column layout, 26+26 or 52px
    columnSpacingNarrow: "1.375rem", // 22px of space before or after a column in a 3-column or more layout, 22+22 or 44px
    heroWideYMargin: "3.875rem", // Desktop margin value above and below the content inside a wide hero
    heroNormalYMargin: "4.875rem", // Desktop margin value above and below the content inside a normal hero
  },
  colors: {
    // Base theme-ui definitions
    text: "#000",
    background: "#fff",
    primary: "#da291c",
    secondary: "#5b6770",
    accent: "#009cde",
    muted: "#a2aaad",
    // Primary & secondary palette
    goreRed: "#da291c",
    goreLightestGray: "#f6f7f7",
    goreLighterGray: "#eceeef",
    goreLightGray: "#a2aaad",
    goreDarkGray: "#5b6770",
    goreWhite: "#fff",
    goreBlack: "#000",
    // Tertiary palette
    goreLightBlue: "#009CDE",
    goreDarkBlue: "#003C71",
    goreLightSteel: "#8DB9CA",
    goreDarkSteel: "#5B7F95",
    goreLightCyan: "#3EB1C8",
    goreDarkCyan: "#005A70",
    goreLightGreen: "#7F9C90",
    goreDarkGreen: "#43695B",
    goreLightBrown: "#E7B75F",
    goreDarkBrown: "#94450B",
    // Tints and opacities for all palettes
    goreRedTinted: "#cc271b",
    goreBlackStartGradient: "rgba(0,0,0,0.75)",
    goreBlackEndGradient: "rgba(0,0,0,0.0)",
    goreFooterGray: "#eceeef",
  },
  fonts: {
    body: "Gore Gustan, Verdana, sans-serif",
    heading: "Gore Gustan, Verdana, sans-serif",
  },
  fontSizes: [
    // Associated size in pixels assumes 16px browser default
    "0.5rem", // 8px
    "0.5625rem", // 9px
    "0.625rem", // 10px
    "0.75rem", // 12px
    "0.875rem", // 14px
    "1rem", // 16px, browser default for most users
    "1.125rem", // 18px
    "1.25rem", // 20px
    "1.375rem", // 22px
    "1.5rem", // 24px
    "1.75rem", // 28px
    "2rem", // 32px
    "2.25rem", // 36px
    "3rem", // 48px
    "3.5rem", // 56px
    "4rem", // 64px
    "4.5rem", // 72px
    "5rem", // 80px
    "6rem", // 96px
  ],
  fontWeights: {
    light: 100,
    normal: 400,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  images: {
    // Logo & Brand Promise defined with position attributes
    // of two child elements of a flex container. Move to header.js
    // file if this combination is not used elsewhere on the site.
    logo: {
      width: "118px",
      display: "block",
      mr: "24px",
      // border: "1px solid green",
      "@media screen and (max-width: calc(25.875rem - 1px))": {
        width: "calc(118px*.85)",
        mr: "calc(24px*.85)",
      },
      "@media screen and (max-width: calc(23.4375rem - 1px))": {
        width: "calc(118px*.725)",
        mr: "calc(24px*.725)",
      },
    },
    brandPromise: {
      width: "206px",
      display: "block",
      pt: "16px",
      // border: "1px solid green",
      "@media screen and (max-width: calc(25.875rem - 1px))": {
        width: "calc(206px*.85)",
        pt: "calc(16px*.85)",
      },
      "@media screen and (max-width: calc(23.4375rem - 1px))": {
        width: "calc(206px*.725)",
        pt: "calc(16px*.725)",
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      fontWeight: "bold",
      display: "inline-block",
      lineHeight: 2.25,
      color: "goreBlack",
      textTransform: "uppercase",
      bg: "transparent",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "goreLightGray",
      borderRadius: 0,
      pb: 0,
      pt: "0.0625rem",
      px: "1.5rem",
      "@media (max-width: 600px)": {
        px: "1rem",
      },
      textDecoration: "none",
      "&:hover": {
        color: "goreWhite",
        bg: "goreRed",
        borderColor: "goreRed",
        cursor: "pointer",
      },
    },
    accented: {
      fontFamily: "body",
      fontWeight: "bold",
      display: "inline-block",
      lineHeight: 2.25,
      color: "goreWhite",
      textTransform: "uppercase",
      bg: "goreRed",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "goreRed",
      borderRadius: 0,
      pb: 0,
      pt: "0.0625rem",
      px: "1.5rem",
      "@media (max-width: 600px)": {
        px: "1rem",
      },
      textDecoration: "none",
      "&:hover": {
        color: "goreRed",
        bg: "goreWhite",
        borderColor: "goreRed",
        cursor: "pointer",
      },
    },
    hamburger: {
      color: "goreWhite",
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      fontWeight: "900",
      padding: "0.25rem 0.625rem 0.625rem 0.625rem",
      borderRadius: 0,
      bg: "goreLightGray",
      "&:hover": {
        color: "goreWhite",
        bg: "goreRed",
        borderColor: "goreRed",
        cursor: "pointer",
      },
    },
  },
  badges: {
    primary: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: 5,
      lineHeight: "1rem",
      textTransform: "uppercase",
      bg: "goreLightestGray",
      color: "goreBlack",
      py: "0.5rem",
      px: "1.25rem",
      borderRadius: 0,
    },
    heroWayfinder: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: 5,
      lineHeight: "1rem",
      textTransform: "uppercase",
      bg: "goreWhite",
      color: "goreDarkGray",
      py: "0.5rem",
      px: "1.25rem",
      borderRadius: 0,
    },
  },
  text: {
    modalTitle: {
      fontSize: 11,
      fontWeight: "light",
      color: "goreBlack",
      lineHeight: "heading",
      mx: "auto",
      display: "block",
    },
  },
  // HTML ELEMENTS
  styles: {
    root: {
      fontFamily: "body",
      margin: 0,
    },
    h1: {
      fontSize: "2.625rem",
      fontWeight: "light",
      color: "goreRed",
      lineHeight: "heading",
    },
    h2: {
      fontSize: 12,
      fontWeight: "light",
      color: "goreDarkGray",
      lineHeight: "heading",
    },
    h3: {
      fontSize: 10,
      fontWeight: "bold",
      color: "goreRed",
      mb: "1.125rem",
      lineHeight: "heading",
    },
    h4: {
      fontSize: 7,
      fontWeight: "bold",
      color: "goreBlack",
      lineHeight: "heading",
    },
    h5: {
      fontSize: 6,
      fontWeight: "bold",
      color: "goreBlack",
      lineHeight: "heading",
    },
    h6: {
      fontSize: 5,
      fontWeight: "bold",
      color: "goreBlack",
      lineHeight: "heading",
    },
    p: {
      fontSize: "1rem",
      lineHeight: "body",
      "&.caption": {
        fontSize: ".8rem",
      },
    },
    a: {
      color: "goreRed",
      "&.primary": {
        color: "goreBlack",
        stroke: "goreRed",
        "&:hover": {
          color: "goreWhite",
          stroke: "goreWhite",
        },
      },
      "&.accented": {
        color: "goreWhite",
        stroke: "goreWhite",
        "&:hover": {
          color: "goreRed",
          stroke: "goreRed",
        },
      },
    },
    ul: {
      margin: 0,
      padding: "0 1rem",
      listStyle: "none",
      li: {
        "&:before": {
          content: `"\\2022"`,
          color: "goreLightGray",
          fontWeight: "bold",
          display: "inline-block",
          width: "1rem",
          ml: "-1rem",
        },
      },
    },
    ol: {
      margin: 0,
      padding: "0 1rem",
    },
    sup: {
      fontSize: ".7em",
      verticalAlign: "top",
    },
    sub: {
      fontSize: ".7em",
      verticalAlign: "bottom",
    },
    blockquote: {
      fontSize: 12,
      fontWeight: "light",
      color: "goreDarkGray",
    },
    img: {
      width: "100%",
      maxWidth: "containerWidth",
      display: "block",
    },
  },
}
